import { onMount, Show } from "solid-js";
import MessageImage from '/src/message-image.jsx';
import Cookies from 'js-cookie';

export default function Message({ message, date, showDate, printDay }) {
  const messageClass = "message message--" + message.type; 
  const id = `ts-${message.timestamp}`;

  function handleClick(data, event) {
    Cookies.set('lastMessage', id, { expires: 7 })
  }

  onMount(() => {
   const lastMessage = Cookies.get('lastMessage');
   if (lastMessage == id) {
     window.location.hash = id;
   }
  });

  return(
    <div id={id}>
      <Show when={printDay}>
        <a id={date.format('YYYY-MM-DD')}></a>
      </Show>
      <Show when={showDate}>
        <div class="timestamp">{message.date}</div>
      </Show>
      <div onClick={[handleClick]} onMouseover={[handleClick]}  class={ messageClass }>
        <Show when={message.mms == 1}>
          <MessageImage src={message.image} />
        </Show>
        <div class="message-text">
          { message.body }
        </div>
      </div>
    </div>
  )
}
