import { Show, createSignal } from "solid-js";

export default function MessageImage({src}) {
  const [loadImage, setLoadImage] = createSignal(false);

  function handleClick() {
    setLoadImage(true);
  }
  
  return(
    <div class="message-image">
      <Show when={src == null}>
        <div class="message-image-unavailable">Image unavailable</div>
      </Show>
      <Show when={loadImage() == false && src}>
        <div onClick={[handleClick]} class="message-image-lazyload">
          <img src="/assets/placeholder.svg" width="600" height="400" />
        </div>
      </Show>
      <Show when={loadImage() && src}>
        <img src={`/assets/sms-images/${src}`} />
      </Show>
    </div>
  );
}
