import { createEffect } from "solid-js"
import { action, redirect } from "@solidjs/router";

const submitLogin = action(async (formData) => {
  const url = '/api/token';
  const response = await fetch(url, {
    method: "POST",
    body: formData
  });  
  if (response.ok) {
    const json = await response.json();
    sessionStorage.setItem('token', json.token);
    throw redirect('/threads');
  }
});

export default function Login(props) {
  return (
    <>
      <div class="form-signin">
        <form action={submitLogin} method="post">
          <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
          <div class="form-floating">
            <input type="password" name="password" class="form-control" placeholder="Password" />
            <label for="password">Password</label>
          </div>

          <button class="w-100 btn btn-lg btn-primary" type="submit">Click for Love</button>
        </form>
      </div>
    </>
  )
}
