import { redirect, route } from "@solidjs/router";
import { createEffect, createResource, onMount, onDestroy } from "solid-js";
import ThreadList from "/src/thread-list.jsx";
import { Datepicker } from 'vanillajs-datepicker';
import dayjs from "dayjs";

const getMessages = async () => {
  const token = sessionStorage.getItem('token');
  if (!token) {
    throw redirect('/login');
  }
  const url = '/api/messages';
  const response = await fetch(url, {
    method: "POST",
    body: new URLSearchParams('token=' + sessionStorage.getItem('token'))
  });
  if (response.ok) {
    return await response.json();
  }
};

const Threads = () => {
  const [messages] = createResource(getMessages);
  let datepicker = null;

  function handleCalendarIconClick(event) {
    event.preventDefault();
    if (datepicker) {
      datepicker.show();
    }
  }

  function handleLockClick(event) {
    event.preventDefault();
    sessionStorage.removeItem('token');
    window.location = '/';
  }

  function handleDateChange(event) {
    const date = dayjs(event.detail.date);
    window.location.hash = '#' + date.format('YYYY-MM-DD');
  }

  onMount(() => {
    const el = document.querySelector('#datepicker-input');
    datepicker = new Datepicker(el, {
      autohide: true
    }); 
    el.addEventListener('changeDate', handleDateChange);
  });


  return(
    <>
      <div class="shadow sticky-top bg-info-subtle">
        <div class="container col-md-5">
          <nav class="navbar">
              <button onClick={[handleLockClick]} type="button" class="btn btn-sm btn-danger shadow-sm">Lock</button>
              <div class="datepicker-wrapper">
                <a href="#" onClick={[handleCalendarIconClick]} ><img src="/assets/calendar.svg" height="30" width="auto" /></a>
                <input type="text" id="datepicker-input" name="datepicker-input" class="datepicker-input"/>
              </div>
          </nav>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-5">
            {messages.loading && (
              <p class="">Loading...</p>
            )}
            {messages() && (
              <ThreadList messages={messages()} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Threads;
