import { createStore } from "solid-js/store"
import { createContext } from "solid-js"
import { render } from "solid-js/web";
import { Router, Route } from "@solidjs/router";

import Login from "/src/login.jsx";
import Threads from "/src/threads.jsx";

render(
    () => (
        <Router>
            <Route path="/" component={Login} />
            <Route path="/threads" component={Threads} />
        </Router>
    ),
    document.getElementById("app")
);
